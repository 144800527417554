import UrlPattern from 'url-pattern';

const construct = url => new UrlPattern('(*)' + url);
export const BASE_URL = '/SupportSrv/SupportSrv.svc/Support';

export const MAIN = construct('/');

export const APPLICANT = construct('/v1/landing/vacancy/:vacancy');
export const VACANCY = construct('/v1/landing/vacancy/nestle');
export const DIRECTIONS = construct('/api/public/vacancy');
export const DICTIONARY = construct('/v1/landing/dictionary/:type');
