import React, { Component, createRef } from 'react';
import { Carousel } from 'antd';
import { withRouter } from 'react-router-dom';
import { debounce, throttle } from 'throttle-debounce';
import { disableBodyScroll } from 'body-scroll-lock';

import Main from '../views/Main';
import Conditions from '../views/Conditions';
import Requirements from '../views/Requirements';
import Advantages from '../views/Advantages';
// import Directions from '../views/Directions';
import About from '../views/About';

import Wave from './Wave';
import Header from './Header';

const order = {
    main: 0,
    conditions: 1,
    advantages: 2,
    requirements: 3,
    // directions: 4,
    about: 4,
};

const menuOrder = {
    0: 'main',
    1: 'conditions',
    2: 'advantages',
    3: 'requirements',
    // 4: 'directions',
    4: 'about',
};


const waveStyle = {
    main: { left: 'calc(-400%)' },
    conditions: { left: 'calc(-300%)' },
    advantages: { left: 'calc(-200%)' },
    requirements: { left: 'calc(-100%)' },
    // directions: { left: 'calc(-100%)' },
    about: { left: '0px' },
};

class Pages extends Component {
    constructor(props) {
        super(props);
        this.slider = createRef();
        this.wheelMax = 0;

        this.state = {
            selected: null,
        };

        this.initSlidePrev();
        this.initSlideNext();
    }

    componentDidMount() {
        const pathname = this.props.location.pathname.replace('/', '');

        if (order[pathname]) {
            this.slider.current.goTo(order[pathname], true);
        } else {
            this.slider.current.goTo(0, true);
            this.setState({ selected: 'main' });
        }

        window.document.addEventListener('wheel', this.handleScroll);

        disableBodyScroll(window.document.body);
    }

    componentWillUnmount() {
        window.document.removeEventListener('wheel', this.handleScroll);
    }

    onSelect = ({ key }, dontAnimate) => {
        if (key === 'modal') {
            return;
        }

        if (this.slider.current) {
            this.slider.current.goTo(order[key] || 0);
        }
    };

    onChange = (from, index) => {
        const key = menuOrder[index];

        if (key === 'modal' || key === this.state.selected) {
            return;
        }

        this.props.history.push('main' === key ? '/' : `/${key}`);
        this.setState({ selected: key || 'main' });
    };

    componentDidUpdate(props, state) {
        if (this.state.selected !== state.selected) {
            this.sliderGoTo(order[this.state.selected]);
        }
    }

    sliderGoTo = debounce(300, (to, noAnimate = false) => {
        this.slider.current && this.slider.current.goTo(to, noAnimate);
    });

    handleScroll = throttle(300, true, (e) => {
        const modal = e.target.closest('.ant-modal-root') || e.target.closest('.ant-select-dropdown');

        if (modal) {
            return false;
        }

        const wheelCurrent = Math.abs(e.deltaY);

        if (this.wheelMax > wheelCurrent) {
            this.wheelMax = wheelCurrent;
            return false;
        }

        if (e.deltaY < 0) {
            this.slideNext.cancel();
            this.initSlideNext();
            this.slidePrev();
        } else if (e.deltaY > 0) {
            this.slidePrev.cancel();
            this.initSlidePrev();
            this.slideNext();
        }

        this.wheelMax = wheelCurrent;
        return false;
    });

    initSlidePrev() {
        this.slidePrev = debounce(100, true, () => {
            if (this.state.selected !== 'main') {
                this.slider.current.prev();
            }
        }, true);
    }

    initSlideNext() {
        this.slideNext = debounce(100, true, () => {
            if (this.state.selected !== 'about') {
                this.slider.current.next();
            }
        }, true);
    }

    nextSlide = () => {
        this.slideNext();
    }

    render() {
        return (
            <div>
                <Header selected={this.state.selected} onSelect={this.onSelect} />
                <Carousel
                    dotPosition='bottom'
                    dots={false}
                    ref={this.slider}
                    beforeChange={this.onChange}
                    className={`slider-${this.state.selected}`}
                    speed={500}
                >
                    <div className='layout-main'>
                        <Main slider={this.slider} nextSlide={this.nextSlide} />
                    </div>
                    <div className='layout-conditions'>
                        <Conditions slider={this.slider} nextSlide={this.nextSlide} />
                    </div>
                    <div className='layout-advantages'>
                        <Advantages slider={this.slider} nextSlide={this.nextSlide} />
                    </div>
                    <div className='layout-requirements'>
                        <Requirements slider={this.slider} nextSlide={this.nextSlide} />
                    </div>
                    {/*
                    <div className='layout-directions'>
                        <Directions slider={this.slider} nextSlide={this.nextSlide} />
                    </div>
                    */}
                    <div className='layout-about'>
                        <About slider={this.slider} />
                        <Wave waveStyle={waveStyle[this.state.selected]} />
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default withRouter(Pages);
