import React, { Component, Fragment } from 'react';
import { Button, Upload, Icon } from 'antd';
import { path } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';
import { pushRollbarError } from '../../../utils/rollbarInit';
import { declOfNum } from '../../../utils/text';

class ResumeUpload extends Component {
    state = {
        fileName: '',
        error: false,
        sizeErrorMessage: undefined,
    };

    getSizeErrorText = () => {
        const { sizeErrorMessage } = this.state;
        const sizeRegex = /^The uploaded file exceeds the (\d+) bytes$/;
        const errorParsed = sizeErrorMessage.match(sizeRegex);

        if (errorParsed) {
            const maxSize = sizeErrorMessage.match(sizeRegex)[1] / 1024 / 1024;

            return `Размер файла превышает ${maxSize} ${declOfNum(maxSize, ['мегабайт', 'мегабайта', 'мегабайт'])}`;
        } else {
            return 'Не удалось загрузить файл';
        }
    }

    onChange = info => {
        switch (info.file.status) {
            case 'upload':
                this.setState({ error: false, sizeErrorMessage: undefined });
                break;
            case 'done':
                this.setState({ fileName: info.file.name });
                this.props.onChange(`http://app.findy.ru/api/file/${info.file.response.id}`);
                break;
            case 'error':
                const sizeErrorMessage = path(['file', 'response', 'message'], info);

                if (sizeErrorMessage) {
                    pushRollbarError(sizeErrorMessage);
                    this.setState({ sizeErrorMessage: 'Vacancy not found' });
                } else {
                    pushRollbarError(info.file.error);
                    this.setState({ error: true });
                }
                break;
            default:
                return;
        }
    }

    delete = () => {
        this.setState({ fileName: '' });
        this.props.onChange(null);
    }

    render() {
        const { input: { value }} = this.props;

        return value ?
            <div className="box-upload">
                <div className="select">{ this.state.fileName }</div>
                <Button onClick={this.delete}><Icon type="delete" /></Button>
            </div> :
            <Fragment>
                <Upload
                    name='file'
                    action='/api/landing/public/file/5ea29c54b90a92001651edcb'
                    onChange={this.onChange}
                    showUploadList={false}
                >
                    <div className="box-upload"><span>Файл не выбран</span> <Button><Icon type="paper-clip" /></Button></div>
                </Upload>
                { this.state.error && <div className='upload-error'>Не удалось загрузить файл</div> }
                { this.state.sizeErrorMessage && <div className='upload-error'>{this.getSizeErrorText()}</div> }
            </Fragment>;
    }
}

export default withFieldWrapper(ResumeUpload);
