import React, { Component } from 'react';
import styled from 'styled-components'
import img from '../../assets/img/nestle.svg';
import imgMin from '../../assets/img/nestle-min.svg';

const Wrap = styled.div`
    padding: 100px 140px 50px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    max-width: 100%;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-image: url(${img});
        background-size: 120px auto;
        background-repeat: no-repeat;
        background-position: 13.8% center;
        top: 50%;
        left: 85px;
        width: 120px;
        height: 468px;
        margin-top: -230px;
        z-index: 2;
    }
    @media (max-width: 1600px) {
        &:after {
            background-size: 100px auto;
            width: 100px;
            height: 400px;
            margin-top: -250px;
        }
    }
    @media (min-width: 961px) {
        height: 100vh;
    }
    @media (max-width: 1440px) {
        padding: 100px 40px 50px 0;
        &:after {
            background-size: 80px auto;
            left: 85px;
            width: 100px;
            height: 370px;
        }
    }
    @media (max-width: 960px) {
        padding: 0;
        &:before {
            transform: skew(0deg);
            left: 0;
        }
         &:after {
            background-image: url(${imgMin});
            top: 40px;
            left: 50%;
            background-size: 130px auto;
            width: 130px;
            height: 60px;
            margin-left: -75px;
            margin-top: 0;
        }
    }
    @media (max-width: 767px) {
        &:after {
            background-size: 100px auto;
            width: 100px;
            height: 40px;
            margin-left: -50px;
            top: 20px;
        }
    }
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const Vk = styled.span`
    width: 36px;
    height: 36px;
    &:before {
        content: '';
        display: inline-block;
        width: 36px;
        height: 36px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNi43MSAzNi43MSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRjOWQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT7QoNC10YHRg9GA0YEgMTE8L3RpdGxlPjxnIGlkPSLQodC70L7QuV8yIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDIiPjxnIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTAsMTguMzVjMC0zLjgyLDAtNy42NCwwLTExLjQ2QTYuOCw2LjgsMCwwLDEsNS4zNS4xOSw2LjY2LDYuNjYsMCwwLDEsNywwSDI5LjczYTYuODMsNi44MywwLDAsMSw3LDdxMCwxMS40LDAsMjIuNzhhNi44NCw2Ljg0LDAsMCwxLTcsN0g3YTYuODUsNi44NSwwLDAsMS03LTdRMCwyNCwwLDE4LjM1Wm0zLjM0LDBWMjkuNTZhMy41MSwzLjUxLDAsMCwwLDMuOCwzLjhIMjkuNTVhMy41MiwzLjUyLDAsMCwwLDMuODItMy44M1Y3LjE3YTMuNTIsMy41MiwwLDAsMC0zLjgyLTMuODNINy4xOUEzLjUzLDMuNTMsMCwwLDAsMy4zNCw3LjJaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMTQuNDQsMTNhMS4xNiwxLjE2LDAsMCwxLDEuMDctLjc3LDM1LjcsMzUuNywwLDAsMSwzLjczLDBjLjgyLjA1LDEsLjQxLDEsMS4yNywwLDEuMiwwLDIuMzktLjA1LDMuNTlhMy4yLDMuMiwwLDAsMCwuMTIuODdjLjE0LjUuNTEuNjIuOS4yN2E0LjI1LDQuMjUsMCwwLDAsLjg5LTFjLjcxLTEuMjMsMS40LTIuNDcsMi0zLjc1YTEuMTYsMS4xNiwwLDAsMSwxLjE2LS43NmMxLjE4LDAsMi4zNiwwLDMuNTQsMCwuODIsMCwxLjExLjM1LjczLDEuMDYtLjU0LDEtMS4xNiwxLjkzLTEuNzksMi44Ny0uNDcuNy0xLDEuMzUtMS41LDIuMDZhMS4wNiwxLjA2LDAsMCwwLC4xNSwxLjYyYy44MS44NSwxLjY2LDEuNjcsMi40NSwyLjU1YTUuMjksNS4yOSwwLDAsMSwuNzIsMS4xMy42MS42MSwwLDAsMS0uMzguOTIuOTMuOTMsMCwwLDEtLjM0LjEyLDM0LjQxLDM0LjQxLDAsMCwxLTMuOCwwLDMuMzcsMy4zNywwLDAsMS0xLjUzLS45QzIzLDIzLjYsMjIuNTMsMjMsMjIsMjIuNDVzLTEuMjctLjUtMS41NC40MWE3LjM3LDcuMzcsMCwwLDAtLjIxLDEuMzNjMCwuNTQtLjI2Ljc5LS44LjgzQTguMzcsOC4zNywwLDAsMSwxMiwyMiwzNi4zOSwzNi4zOSwwLDAsMSw3LDEzLjZjLS4yNy0uNTctLjA4LS44OC41NS0uOTEsMS4wOCwwLDIuMTUtLjA2LDMuMjMtLjA2YTEuMDgsMS4wOCwwLDAsMSwxLC44MSwxNywxNywwLDAsMCwyLjUsNC4zNmMuMjMuMjguNjQuNjIuOTQuNTlzLjUtLjU3LjUxLTFjLjA1LTEsLjA4LTIsMC0zQTEuMzUsMS4zNSwwLDAsMCwxNC40NCwxM1oiLz48L2c+PC9nPjwvc3ZnPg==');
    }
`;

const TextWrap = styled.div`
    h2 {
        font-weight: bold;
        color: #264C9D;
        font-size: 34px;
        margin-bottom: 40px;
        @media (max-width: 1440px) {
            margin-bottom: 25px;
        }
        @media (max-width: 767px) {
            margin-bottom: 5px;
            font-size: 18px;
        }
    }
`;

const Social = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    height: 36px;
    width: 120px;
    left: 85px;
    bottom: 65px;
    @media (max-width: 1600px) {
        width: 100px;
    }
    @media (max-width: 960px) {
        display: none;
        width: 130px;
    }
    @media (max-width: 1440px) {
        width: 80px;
    }
    @media (max-width: 767px) {
        width: 100px;
    }
`;

const Content = styled.div`
    display: flex;
    padding-left: 39%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex: 12 0 100%;
    p {
        font-size: 20px;
        line-height: 26px;
        color: #264C9D;
        margin-bottom: 80px;
    }
    ul {
        font-size: 22px;
        line-height: 26px;
        font-weight: bold;
        padding-left: 0;
        color: #264C9D;
        list-style: none;
        display: flex;
        margin: 0 -10px;
        &.mobile {
            display: none;
        }
        li {
            display: inline-flex;
            width: 100%;
            align-items: center;
            text-align: center;
            padding: 10px;
            margin: 0;
            min-height: 75px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 0;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 1440px) {
        padding-left: 41%;
        p {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 40px;
        }
    }
    @media (max-width: 960px) {
        padding: 130px 20px 20px;
        align-items: flex-start;
        justify-content: center;
        p {
            font-size: 16px;
        }
    }
    @media (max-width: 767px) {
        padding: 55px 20px 20px;
        p {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 8px;
        }
        .row-1, .row-2 {
            display: none;
        }
        ul {
            flex-wrap: wrap;
            margin: -5px;
            &.mobile {
                display: flex;
            }
            li {
                padding: 8px;
                min-height: 40px;
                width: 33%;
                &:nth-child {
                    &(1),&(2),&(3),&(4){
                        width: 25%;
                        padding: 8px 3px;
                    }
                }
            }
        }
    }
    @media (min-width: 767px) {
        .row-1 {
            li {
                max-width: 15%;
            }
        }
        .row-2 {
            li {
                max-width: 13%;
            }
        }
    }
`;

export default class About extends Component {
    render() {
        return (
            <Wrap>
                <ContentWrap className="container">
                    <Content>
                        <Social>
                            <a href="https://vk.com/nestlerussia">
                                <Vk />
                            </a>
                        </Social>
                        <TextWrap>
                            <h2>О компании</h2>
                            <p>
                                «Нестле» – крупнейшая в мире компания-производитель продуктов питания и напитков, эксперт в области правильного питания и здорового образа жизни.
                                Компания ведёт бизнес в 186 странах мира, общее число сотрудников насчитывает 270 тыс. человек.
                                Цель «Нестле» - повышение качества жизни и вклад в здоровое будущее.
                                В линейку продукции компании входит более 2 тыс. брендов.
                                Штаб-квартира «Нестле» находится в г. Веве (Швейцария), где более 150 лет назад началась история компании.
                                В «Нестле Россия» работают более 7 тысяч сотрудников, в России у компании 6 производственных площадок.
                                Компания неоднократно получала премию Randstad Award как самый привлекательный работодатель в сегменте Food FMCG.
                            </p>
                        </TextWrap>
                    </Content>
                </ContentWrap>
            </Wrap>
        );
    }
}

