import React, { Component } from 'react';
import { Checkbox } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class Checkboxes extends Component {
    static defaultProps = {
        options: []
    };

    onChange = values => {
        !this.props.single && this.props.onChange(values.length ? values : undefined);
    }

    onChangeCheckbox = e => {
        if (this.props.single) {
            this.props.onChange(e.target.checked ? [e.target.value] : undefined);
        }
    }

    render() {
        const { input: { value, name }, options, transform, namePath, valuePath } = this.props;
        const opts = transform ? transform(options) : options;

        const checkboxStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft: 0
        };

        return <Checkbox.Group
            value={value || []}
            onChange={this.onChange}>
            { opts.map(option =>
                <Checkbox
                    key={`${name}-${option[valuePath]}`}
                    value={option[valuePath]}
                    style={checkboxStyle}
                    onChange={this.onChangeCheckbox}>
                    { option[namePath] }
                </Checkbox>
            )}
        </Checkbox.Group>
    }
}


export default withFieldWrapper(Checkboxes);
