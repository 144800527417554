import React from 'react';
import Masked from 'react-text-mask';

import withFieldWrapper from '../../hocs/withFieldWrapper';

const MaskedInput = ({ input: { value, onChange, name }, mask, pipe, placeholder, size, guide = true }) => (
    <Masked
        name={name}
        value={value}
        mask={mask}
        onChange={onChange}
        className={`ant-input ant-input-mask form-control ${size}`}
        placeholderChar={'\u2000'}
        placeholder={placeholder}
        keepCharPositions={false}
        guide={guide}
        pipe={pipe}
    />
);

export default withFieldWrapper(MaskedInput);
