import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Form as FormComponent } from 'antd';
import { equals, assocPath, head } from 'ramda';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';

import RequiredFieldsContext from '../../context/RequiredFieldsContext';
import { getRequiredFields } from '../../utils/getRequiredFields';

const getErrorInputs = () => document.querySelectorAll('.error input');
const focusFirstErrorInput = (errorInputs) => ({
    focus: () => {
        const element = head(errorInputs);

        if (element) {
            element.focus();
        }
    },
})

const focusOnError = createDecorator(
    getErrorInputs,
    focusFirstErrorInput,
);

const withFormWrapper = (formOptions = {}) => WrappedComponent =>
    class FormWrapper extends Component {
        static propTypes = {
            formAction: PropTypes.object,
            mapBeforeSubmit: PropTypes.func
        };

        constructor(props) {
            super(props);

            this.state = {
                initialValues: this.getInitialValues(props)
            };
        }

        componentDidUpdate(prev) {
            const initialValues = this.getInitialValues();

            if (!equals(initialValues, this.getInitialValues(prev))) {
                this.setState({ initialValues });
            }
        }

        getInitialValues = (props = this.props) => {
            return formOptions.mapPropsToValues ? formOptions.mapPropsToValues(props) : this.props.initialValues || {};
        }

        onSubmit = values => {
            const { formAction } = this.props;

            formAction && formAction.dispatch(
                formOptions.mapBeforeSubmit ? formOptions.mapBeforeSubmit(values, this.props) : values
            );
        }

        handleSubmit = (e, handleSubmit) => {
            handleSubmit(e);
        }

        validate = values => {
            let schema = formOptions.validationSchema;

            if (!schema) {
                return {};
            }

            if (typeof schema === 'function') {
                schema = schema(this.props, values);
            }

            try {
                schema.validateSync(values, { abortEarly: false });
            } catch (e) {
                return e.inner.reduce((errors, error) => assocPath(error.path.split('.'), error.message, errors), {});
            }
        }

        render() {
            return <RequiredFieldsContext.Provider value={getRequiredFields(formOptions.validationSchema)}>
                <Form
                    subscription={{
                        submitting: true,
                        invalid: true,
                        submitFailed: true,
                        valid: true,
                        error: true,
                        errors: true,
                    }}
                    onSubmit={this.onSubmit}
                    initialValues={this.state.initialValues}
                    validate={this.validate}
                    mutators={arrayMutators}
                    decorators={[focusOnError]}
                    render={props =>
                        <FormComponent
                            onSubmit={props.handleSubmit}
                            className={this.props.className}
                            {...this.props.formItemLayout}
                            noValidate
                        >
                            <WrappedComponent {...this.props} {...props} />
                        </FormComponent>
                    }
                />
            </RequiredFieldsContext.Provider>;
        }
    }

export default withFormWrapper;
