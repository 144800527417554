import tinygradient from 'tinygradient';

import { ShaderProgram } from './program';

const pointSize = .4;

export const init = (isMobile) => {
    const wave = document.querySelector('.slick-slide:not(.slick-cloned) .particles');
    const originalHeight = document.body.clientHeight;

    if (!wave || wave.querySelector('canvas')) {
        return;
    }

    const gradient = tinygradient([
        { color: '#2d3dc7', pos: 0 },
        { color: '#2d3dc7', pos: .3 },
        { color: '#16a1dc', pos: .4 },
        { color: '#00ffff', pos: .5 },
        { color: '#16a1dc', pos: .6 },
        { color: '#2d3dc7', pos: .7 },
        { color: '#2d3dc7', pos: 1 }
    ]);

    return new ShaderProgram(wave, {
        isMobile,
        texture: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAb1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8v0wLRAAAAJHRSTlMAC/goGvDhmwcExrVjWzrm29TRqqSKenRXVklANSIUE8mRkGpv+HOfAAABCElEQVQ4y4VT13LDMAwLrUHteO+R9f/fWMfO6dLaPeKVEECRxOULWsEGpS9nULDwia2Y+ALqUNbAWeg775zv+sA4/FFRMxt8U2FZFCVWjR/YrH4/H9sarclSKdPMWKzb8VsEeHB3m0shkhVCyNzeXeAQ9Xl4opEieX2QCGnwGbj6GMyjw9t1K0fK9YZunPXeAGsfJtYjwzxaBnozGGorYz0ypK2HzQSYx1y8DgSRo2ewOiyh2QWOEk1Y9OrQV0a8TiBM1a8eMHWYnRMy7CZ4t1CmyRkhSUvP3gRXyHOCLBxNoC3IJv//ZrJ/kxxUHPUB+6jJZZHrpg6GOjnqaOmzp4NDR48OLxn/H27SRQ08S0ZJAAAAAElFTkSuQmCC',
        uniforms: {
            size: { type: 'float', value: pointSize },
            field: { type: 'vec3', value: [ 0, 0, 0 ] },
            speed: { type: 'float', value: 6 },
        },
        vertex: `
            #define M_PI 3.1415926535897932384626433832795

            precision highp float;

            attribute vec4 a_position;
            attribute vec4 a_color;

            uniform float u_time;
            uniform float u_size;
            uniform float u_speed;
            uniform vec3 u_field;
            uniform mat4 u_projection;
            uniform mat4 u_transform;

            varying vec4 v_color;

            void main() {
                vec3 pos = a_position.xyz;

                pos.y += (
                    cos(pos.x / u_field.x * M_PI * 2.0 - u_time * u_speed)
                    + cos(pos.x / u_field.x * M_PI * 8.0 - u_time * u_speed)
                    + cos(pos.x / u_field.x * M_PI * 1.0 - u_time * u_speed)
                    + sin(pos.x / u_field.x * M_PI * 2.0 - u_time * u_speed)
                    + sin(pos.x / u_field.x * M_PI * 7.0 - u_time * u_speed)
                    + sin(pos.z / u_field.z * M_PI * 1.0 - u_time * u_speed)
                ) * u_field.y;

                gl_Position = u_projection * vec4( pos.xyz, a_position.w );
                gl_PointSize = ( u_size / gl_Position.w ) * 180.0;
                v_color = a_color;
            }`,
        fragment: `
            precision highp float;
            uniform sampler2D u_texture;
            varying vec4 v_color;

            void main() {
                gl_FragColor = v_color * texture2D(u_texture, gl_PointCoord);
            }`,
        onResize( w, h, dpi ) {
            if (isMobile) {
                w = originalHeight + 500;

                const position = [], color = []
                const width = 240 * ( w / h )

                const depth = 300
                const height = 10
                const distance = 5
                const distanceX = .1;
                const colors = gradient.rgb(width / distanceX + 1);
                let xIndex = 0;

                for ( let x = 0; x < width; x += distanceX ) {
                    for ( let z = 0; z < 90; z+= distance ) {
                        position.push( - width / 2 + x, -30, -depth / 2 + z );

                        const { _r, _g, _b } = colors[xIndex];
                        color.push(_r / 266, _g / 266, _b / 266, 1);
                    }
                    xIndex += 1;
                }
                this.uniforms.field = [ width, height, depth ]
                this.buffers.position = position
                this.buffers.color = color
                this.uniforms.size = ( h / 250) * pointSize * dpi
            } else {
                w = Math.max(document.body.clientWidth + 500, 2180);

                const position = [], color = []
                const width = 240 * ( w / h )
                const depth = 300
                const height = 10
                const distance = 5
                const distanceX = .1;
                const colors = gradient.rgb(width / distanceX + 1);

                let xIndex = 0;

                for ( let x = 0; x < width; x += distanceX ) {
                    for ( let z = 0; z < 90; z+= distance ) {
                        position.push( - width / 2 + x, -30, -depth / 2 + z );

                        const { _r, _g, _b } = colors[xIndex];
                        color.push(_r / 266, _g / 266, _b / 266, 1);
                    }
                    xIndex += 1;
                }
                this.uniforms.field = [ width, height, depth ]
                this.buffers.position = position
                this.buffers.color = color
                this.uniforms.size = ( h / 250) * pointSize * dpi
            }
        },
    });
};
