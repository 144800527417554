export const directions = [
    {
        name: 'Маркетинг и PR',
    },
    {
        name: 'Финансы и аналитика',
    },
    {
        name: 'Логистика и планирование',
    },
    {
        name: 'Продажи',
    },
];

export const conditions = [
    { value: '20-35 часов' },
    { value: '40 часов' },
];

export const levels = [
    {
        id: 16,
        name: 'Базовый',
    },
    {
        id: 32,
        name: 'Средний',
    },
    {
        id: 2,
        name: 'Свободный',
    }
];

export const educations = [
    {
        id: 34,
        name: '3 курс (бакалавриат)',
    },
    {
        id: 44,
        name: '4 курс (бакалавриат)',
    },
    {
        id: 53,
        name: '5 курс (специалитет)',
    },
    {
        id: 63,
        name: '6 курс (специалитет)',
    },
    {
        id: 15,
        name: '1 курс (магистратура)',
    },
    {
        id: 25,
        name: '2 курс (магистратура)',
    },
    {
        id: 2,
        name: 'Аспирантура',
    },
];

export const educationValues = {
    34: {
        level: 4,
        year: 3,
    },
    44: {
        level: 4,
        year: 4,
    },
    53: {
        level: 3,
        year: 5,
    },
    63: {
        level: 3,
        year: 6,
    },
    15: {
        level: 5,
        year: 1,
    },
    25: {
        level: 5,
        year: 2,
    },
    2: {
        level: 2,
        year: '',
    },
};
