import { takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import { CHANGE_LANGUAGE } from '../constants/actionTypes';

export default function* watchLanguageChange() {
    yield takeEvery(CHANGE_LANGUAGE, function (action) {
        moment.locale(action.payload);
        localStorage.setItem('language', action.payload);
    });
}
