import React, { Component } from 'react';
import styled from 'styled-components'
import { Button } from 'antd';

import mainPhoto from '../../assets/img/main-photo.png';
import mainLetter from '../../assets/img/main-letter.png';
import minMainPhoto from '../../assets/img/min-main-photo.png';
import minMainLetter from '../../assets/img/min-main-letter.png';
import logo from '../../assets/img/logo.svg';

import { ModalContext } from '../../context/modal';

const Wrap = styled.div`
    padding: 100px 0 80px;
    margin: 0 140px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1440px) {
        margin: 0 40px;
    }
    @media (min-width: 961px) {
        height: 100vh;
    }
    @media (max-width: 960px) {
        padding: 40px 0 60px;
        margin: 0 40px;
    }
`;

const Bg = styled.div`
    position: relative;
    height: calc(100vh - 350px);
    width: 100%;
    background-image: url(${mainPhoto});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 960px) {
        background-image: url(${minMainPhoto});
        height: calc(100vh - 180px);
    }
    &:after {
        content: '';
        height: calc(100vh - 350px);
        width: 100%;
        background-image: url(${mainLetter});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        left: 0;
        z-index: -2;
        @media (max-width: 960px) {
            background-image: url(${minMainLetter});
        }
    }
    @media (max-width: 960px) {
        &:after {
            height: calc(100vh - 180px);
        }
    }
`;
const LogoImg = styled.img`
    height: 88px;
    @media (max-width: 960px) {
        height: 60px;
    }
    @media (max-width: 767px) {
        height: 34px;
    }
`;

const Content = styled.div`
    display: flex;
    margin-top: 20px;
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const Internship = styled.div`
    h2 {
        font-weight: bold;
        font-size: 30px;
        color: #264C97;
        @media (max-width: 1440px) {
            font-size: 24px;
        }
    }
    @media (max-width: 960px) {
        order: 1;
        h2 {
            display: none;
        }
        .ant-btn-lg {
            font-size: 18px;
            padding: 0 15px;
            height: 46px;
            margin-left: 16px;
        }
    }
`;

export default class Main extends Component {
    render() {
        return (
            <Wrap>
                <Bg />
                <Content className="container">
                    <Footer>
                        <Internship>
                            <h2>Пройди стажировку в ритме Нестле</h2>
                            <ModalContext.Consumer>
                                { ({ showModalApplication }) => (
                                    <Button type="primary" size="large" onClick={() => showModalApplication()}>
                                        Подать заявку
                                    </Button>
                                )}
                            </ModalContext.Consumer>
                        </Internship>
                        <LogoImg src={logo} alt="" />
                    </Footer>
                </Content>
            </Wrap>
        );
    }
}

