
import React, { Fragment } from 'react';
import styled from 'styled-components'
import { Button, Modal, Form, Row, Col, Spin } from 'antd';
import { asyncConnect } from 'react-async-client';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import { ModalContext } from '../context/modal';

import { postApplicant, getDictionary } from '../actions/asyncActions';
import { directions as directionsStatic, conditions, educations, levels } from '../constants/dictionaries';
import { PHONE_VAIDATOR } from '../constants/regExp';
import { getPhoneMask } from '../utils/masks';

import withFormWrapper from './hocs/withFormWrapper';
import InputComponent from './forms/formComponents/Input';
import MaskedInput from './forms/formComponents/MaskedInput';
import Select from './forms/formComponents/Select';
import Checkboxes from './forms/formComponents/Checkboxes';
import OpdCheckbox from './forms/formComponents/OpdCheckbox';
import SearchSelect from './forms/formComponents/SearchSelect';
import ResumeUpload from './forms/formComponents/ResumeUpload';
import { pushRollbarError } from '../utils/rollbarInit';

const required = value => (value ? undefined : 'Обязательное поле');

const validationSchema = yup.object().shape({
    email: yup.string().email('Неверный формат email'),
    phone: yup.string().matches(PHONE_VAIDATOR, 'Номер телефона должен содержать 11 цифр'),
});

const Close = styled.span`
    width: 42px;
    height: 42px;
    background-size: 42px;
    display: inline-block;
    font-weight: bold;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Mi42OSA0Mi42OSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMyNjRjOWQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT7QoNC10YHRg9GA0YEgMTQ8L3RpdGxlPjxnIGlkPSLQodC70L7QuV8yIiBkYXRhLW5hbWU9ItCh0LvQvtC5IDIiPjxnIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iLTQuNDEiIHk9IjE2LjkxIiB3aWR0aD0iNTEuNSIgaGVpZ2h0PSI4Ljg3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOC44NCAyMS4zNCkgcm90YXRlKC00NSkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9Ii00LjQxIiB5PSIxNi45MSIgd2lkdGg9IjUxLjUiIGhlaWdodD0iOC44NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjEuMzQgLTguODQpIHJvdGF0ZSg0NSkiLz48L2c+PC9nPjwvc3ZnPg==');
    @media (max-width: 960px) {
        width: 24px;
        height: 24px;
        background-size: 24px;
    }
`;

const FormRow = styled(Row)`
    display: flex;
`;

const FormCol = styled(Col)`
    display: flex;
    flex-direction: column;
    .form-group {
        .ant-row:last-child {
            margin: 0;
        }
    }
`;

const Controls = styled.div`
    display: flex;
    justify-content: center;
    button {
        min-width: 260px;
    }
    @media (max-width: 767px) {
        .form-submit {
            width: 100%;
            button {
                min-width: 100%;
            }
        }
    }
`;

const Modals = asyncConnect({
    formAction: postApplicant
        .withErrorHandler(({ formAction: { meta: { error }}}) => {
            pushRollbarError(error);
        }),
    getDictionary: getDictionary
        .withPayload(() => ({ type: 'TownList' }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(withFormWrapper({
    validationSchema,
    mapPropsToValues: ({ state, initialValues }) => ({
        ...initialValues,
        ...(state.params || {})
    })
})(({ state, modalCancel, toggleModalOPD, handleSubmit, form, vacancy = {}, initialValues, formAction: { meta }, getDictionary }) => {
    const directions = vacancy.desiredPosition || directionsStatic;

    return (
        <Fragment>
            <Modal
                style={{ top: 40 }}
                className="modal-application"
                visible={state.showApplication}
                onCancel={() => {
                    form.reset(initialValues);
                    modalCancel();
                }}
                width={845}
                closeIcon={ <Close /> }
                footer={false}
                destroyOnClose={true}
                title={meta.success ? (
                    <div className='success-wrap'>
                        <p>
                            Ваша заявка на стажировку в ритме Нестле успешно зарегистрирована и направлена в отдел подбора персонала!
                        </p>
                        <p>
                            Имейте в виду, что процесс рассмотрения заявок может занять некоторое время. Со своей стороны мы сделаем всё возможное, чтобы в кратчайшие сроки информировать Вас о его результатах.
                        </p>
                        <div>
                            Спасибо за Ваше желание начать свою карьеру с нами!
                        </div>
                    </div>
                ) : null}
            >
                { !meta.success && (
                    <Form>
                        <FormRow gutter={20}>
                            <FormCol span={12}>
                                <div className="form-group">
                                    <h3>Регистрация</h3>
                                    <Field
                                        name="firstName"
                                        component={InputComponent}
                                        placeholder="Имя"
                                        validate={required}
                                    />
                                    <Field
                                        name="lastName"
                                        component={InputComponent}
                                        placeholder="Фамилия"
                                        validate={required}
                                    />
                                    <Field
                                        name="town"
                                        component={SearchSelect}
                                        placeholder="Город"
                                        options={getDictionary.data}
                                        validate={required}
                                    />
                                    <Field
                                        name="email"
                                        component={InputComponent}
                                        placeholder="E-mail"
                                        validate={required}
                                    />
                                    <Field
                                        name="phone"
                                        component={MaskedInput}
                                        placeholder="Телефон"
                                        mask={getPhoneMask}
                                        validate={required}
                                    />
                                </div>

                                <div className="education form-group">
                                    <h3>Образование</h3>
                                    <Field
                                        name="vuz"
                                        component={InputComponent}
                                        placeholder="ВУЗ"
                                        validate={required}
                                    />
                                    <Field
                                        name="educationLevel"
                                        component={Select}
                                        options={educations}
                                        placeholder="Уровень образования"
                                        validate={required}
                                    />
                                    <Field
                                        name="languageLevelEn"
                                        component={Select}
                                        options={levels}
                                        placeholder="Уровень английского языка"
                                        validate={required}
                                    />
                                </div>
                            </FormCol>
                            <FormCol span={12}>
                                <div className="form-group">
                                    <h3>Выберите наиболее приоритетное для вас направление</h3>

                                    <Field
                                        name="direction"
                                        component={Select}
                                        placeholder="Выберите направление"
                                        namePath='name'
                                        valuePath='name'
                                        options={directions}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group">
                                    <h3>Выберите направление второго приоритета</h3>
                                    <Field
                                        name="directionSecond"
                                        component={Select}
                                        placeholder="Выберите направление"
                                        namePath='name'
                                        valuePath='name'
                                        options={directions}
                                        validate={required}
                                    />
                                </div>
                                <div className="form-group">
                                    <h3>Резюме</h3>
                                    <Field
                                        name="resume"
                                        component={ResumeUpload}
                                        validate={required} />
                                </div>
                                <div className="form-group">
                                    <h3>Сколько часов в неделю вы готовы работать ?</h3>
                                    <Row>
                                        <Col span={24}>
                                            <Field
                                                name="conditions"
                                                component={Checkboxes}
                                                namePath='value'
                                                valuePath='value'
                                                options={conditions}
                                                validate={required}
                                                single
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`form-group ${meta.error ? 'has-error' : ''}`}>
                                    <Field
                                        name="opd"
                                        component={OpdCheckbox}
                                        validate={required}
                                        openOPD={toggleModalOPD}
                                    />
                                </div>
                            </FormCol>
                        </FormRow>

                        <Controls>
                            <div className={`form-group form-submit ${meta.error ? 'has-error' : ''}`}>
                                <Button key="back" onClick={handleSubmit} loading={meta.pending}>
                                    Отправить
                                </Button>
                                { meta.error && (
                                    <b className='ant-form-explain'>
                                        Ошибка. Не удалось отправить заявку. Попробуйте позже
                                    </b>
                                )}
                            </div>
                        </Controls>
                    </Form>
                )}
            </Modal>
            <Modal
                style={{ top: 35 }}
                className="modal-reg"
                visible={state.showOPD}
                onCancel={() => {
                    toggleModalOPD();
                }}
                footer={false}
                width={900}
                closeIcon={ <Close /> }
                destroyOnClose={true}
            >
                <Row>
                    <Col span={24}>
                        <div className="nestle-opd">
                            <div className='text-center'>
                                <h3>Согласие на&nbsp;обработку персональных данных</h3>
                            </div>
                            <p>
                                Оператору: ООО &laquo;Нестле Россия&raquo; (далее по&nbsp;тексту&nbsp;&mdash; Компания, местонахождение:<br />
                                Российская Федерация, Павелецкая&nbsp;пл., д.&nbsp;2, стр.&nbsp;1, Москва, 115054)
                            </p>
                            <p>
                                Я, (далее&nbsp;&mdash; &laquo;Кандидат&raquo;), свободно, своей волей и&nbsp;в&nbsp;своем интересе, в&nbsp;соответствии со&nbsp;статьей 9&nbsp;Федерального закона от&nbsp;27&nbsp;июля 2006 года &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; предоставляю конкретное, предметное, информированное, сознательное и&nbsp;однозначное согласие Оператору на&nbsp;обработку моих персональных данных (далее&nbsp;&mdash; &laquo;ПДн&raquo;) на&nbsp;указанных ниже условиях.
                            </p>
                            <p>
                                Согласие на&nbsp;обработку предоставляется Оператору для обработки ПДн Кандидата с&nbsp;целью подбора, в&nbsp;том&nbsp;числе рассмотрение резюме, на&nbsp;замещение вакантных должностей, формирования внешнего кадрового резерва Оператора и&nbsp;прохождения тестирования для этого.
                            </p>
                            <p>
                                Согласие на&nbsp;обработку предоставляется Оператору в&nbsp;отношении следующих действий с&nbsp;ПДн Кандидата, осуществляемых с&nbsp;использованием автоматизированной обработки, неавтоматизированной обработки (без использования средств автоматизации) и&nbsp;смешанного способа обработки ПДн: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление и&nbsp;уничтожение; трансграничная передача на&nbsp;территорию Беларуси и&nbsp;Казахстана.
                            </p>
                            <p>
                                Мои персональные данные, в&nbsp;отношении которых дается настоящее согласие:
                            </p>
                            <ul>
                                <li>фамилия, имя, отчество;</li>
                                <li>дата и&nbsp;место рождения;</li>
                                <li>контактные данные (адрес электронной почты, номер мобильного телефона);</li>
                                <li>сведения об&nbsp;адресе регистрации&nbsp;/ адресе фактического проживания&nbsp;/ месте пребывания;</li>
                                <li>сведения о&nbsp;документе, удостоверяющем личность (наименование документа, серия и&nbsp;номер, когда и&nbsp;кем выдан);</li>
                                <li>сведения о&nbsp;гражданстве;</li>
                                <li>сведения о&nbsp;семейном положении, составе семьи;</li>
                                <li>сведения об&nbsp;образовании (уровень образования, наименование учебного заведения и&nbsp;факультета, период обучения, форма обучения, специальность, квалификация, реквизиты документа об&nbsp;образовании, сведения о&nbsp;дополнительном образовании, сертификации);</li>
                                <li>сведения об&nbsp;опыте работы и&nbsp;трудовом стаже (места работы, занимаемые должности, период работы, стаж, основания приема и&nbsp;увольнения);</li>
                                <li>сведения о&nbsp;квалификационной категории (разряд/класс (квалификация));</li>
                                <li>информация о&nbsp;владении иностранными языками, степень владения;</li>
                                <li>фотография Кандидата;</li>
                                <li>сведения о&nbsp;воинской обязанности;</li>
                                <li>сведения о&nbsp;доходах;</li>
                                <li>результаты, полученные с&nbsp;помощью тестов способностей и&nbsp;опросников Оператора;</li>
                                <li>наименование организаций, учредителем которых является Кандидат.</li>
                            </ul>
                            <p>
                                Я&nbsp;согласен с&nbsp;тем, что мои персональные могут быть переданы Оператором на&nbsp;указанных ниже условиях компаниям:
                            </p>
                            <ul>
                                <li>ООО &laquo;Бизнес Психологи&raquo;, расположенному по&nbsp;адресу 197022, Россия, г. Санкт-Петербург, ул. Профессора Попова, дом&nbsp;37, литер&nbsp;А с&nbsp;целью тестирования Кандидата;</li>
                                <li>ООО &laquo;Нестле Кубань&raquo;, расположенному по адресу: 352700, Россия, Краснодарский край, г. Тимашевск, ул. Гибридная, 2а с целью рассмотрения резюме;</li>
                                <li>ООО "Сириал Партнерс Рус", расположенному по адресу: 115054, Россия, г. Москва, Павелецкая пл., д.2 стр.1 с целью рассмотрения резюме</li>
                                <li>ООО &laquo;Атриум Инновейшенс РУС&raquo;, расположенному по адресу: 121059, Россия, г. Москва, Павелецкая пл., д.2 стр.1 с целью рассмотрения резюме;</li>
                                <li>ТОО &laquo;Нестле Фуд Казахстан&raquo;, расположенному по&nbsp;адресу: 050040, Республика Казахстан, г. Алматы, Бостандыкский район, улица Тимирязева, дом 28&nbsp;&laquo;В&raquo; с&nbsp;целью рассмотрения резюме;</li>
                                <li>ООО &laquo;Нестле Бел&raquo;, расположенному по адресу: 220140, г. Минск, Республика Беларусь, Домбровская ул., д. 9, офис 3.1.1. с целью рассмотрения резюме;</li>
                                <li>ООО "Центр Поддержки Бизнеса Нестле", расположенному по&nbsp;адресу: 443013&nbsp;Россия, Самарская&nbsp;область, г.&nbsp;Самара, Московское&nbsp;ш, д.&nbsp;4 к.&nbsp;4, офис&nbsp;408 с целью рассмотрения резюме;</li>
                                <li>ООО &laquo;Директум&raquo;, расположенное по&nbsp;адресу: 426011, г. Ижевск, переулок Северный, д.61&nbsp;с целью предоставления доступа к&nbsp;корпоративной системе электронного документооборота;</li>
                                <li>ООО &laquo;1С-ВайзЭдвайс&raquo;, расположенное по&nbsp;адресу: 109456, Российская Федерация, Москва, Рязанский проспект, д.75 кор. 4, этаж 12 пом. XXIV часть комнаты 8 с целью предоставления доступа к корпоративной системе электронного документооборота.</li>
                            </ul>
                            <p>
                                Настоящее согласие действует в&nbsp;течение 5&nbsp;лет со&nbsp;дня его подписания.<br/>
                                Настоящее согласие может быть отозвано путем направления в&nbsp;адрес Оператора сообщения об&nbsp;отзыве согласия на обработку ПДн в&nbsp;произвольной письменной форме не&nbsp;менее чем за&nbsp;30 (тридцать) дней до предполагаемой даты отзыва настоящего согласия почтой (с&nbsp;уведомлением о вручении) на имя Оператора с&nbsp;указанием паспортных данных. В&nbsp;этом случае в&nbsp;отношении меня будет невозможным включение данных Кандидата во внешний кадровый резерв и/или оформление в&nbsp;качестве работника Оператора, и&nbsp;я&nbsp;осознаю последствия отзыва такого согласия.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    );
}));

const ModalsComponent = ({ initialValues, vacancy }) => (
    <ModalContext.Consumer>
        { context => (
            <Modals {...context} initialValues={initialValues} vacancy={vacancy} />
        )}
    </ModalContext.Consumer>
);

export default ModalsComponent;

export const ModalErrors = ({ meta }) => (
    <ModalContext.Consumer>
        { ({ state, modalCancel }) => (
            <Modal
                style={{ top: 40 }}
                className="modal-reg"
                visible={state.showApplication}
                onCancel={() => {
                    modalCancel();
                }}
                footer={false}
                width={510}
                closeIcon={ <Close /> }
                destroyOnClose={true}
            >
                <Row>
                    <Col span={12}>
                        <div className="form-group">
                            { !meta.pending ? <h3>Не удалось установить связь с сервером</h3> : <Spin /> }
                        </div>
                    </Col>
                </Row>
            </Modal>
        )}
    </ModalContext.Consumer>
);
