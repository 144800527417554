import React, { Component } from 'react';

export const ModalContext = React.createContext({});

export class ModalContextProvider extends Component {
    state = {
        showApplication: false,
        showOPD: false,
        params: null
    };

    showModalApplication = params => {
        this.setState({
            showApplication: true,
            params
        });
    };

    modalCancel = e => {
        this.setState({
            showApplication: false,
            showOPD: false,
            params: null
        });
    };


    toggleModalOPD = () => {
        this.setState({
            showOPD: !this.state.showOPD,
        });
    };

    render() {
        return (
            <ModalContext.Provider value={{
                state: this.state,
                showModalApplication: this.showModalApplication,
                toggleModalOPD: this.toggleModalOPD,
                modalCancel: this.modalCancel,
            }}>
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}
