import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'element-closest-polyfill';

import React from 'react';
import { render } from 'react-dom';
import moment from 'moment';
import 'moment/locale/ru';
import 'rc-dialog/assets/index.css';
import './utils/validationLocale';
import 'yup';

import App from './components/App';
import configureStore from './store/configureStore';

import './styles/index.scss';
import { setBaseUrl, setLanguage } from './utils/http';
import { provideResponseInterceptors, provideRequestInterceptors } from './utils/httpInterceptors';
import rollbarInit from './utils/rollbarInit';

if (process.env.NODE_ENV === 'production') {
    rollbarInit({
        token: '8f47b71c320b4e5d907f062cd3eba1fc',
        version: process.env.REACT_APP_VERSION,
        environment: window.location.host
    });

    window._version = {
        version: process.env.REACT_APP_VERSION,
    };
}

setBaseUrl();
setLanguage();
provideResponseInterceptors();
provideRequestInterceptors();

moment.locale(localStorage.getItem('language') || 'ru');

const store = configureStore();
const MOUNT_NODE = document.getElementById('root');

render(<App store={store} />, MOUNT_NODE);
