import axios from 'axios';

import * as urls from '../../constants/urls';
import { educationValues } from '../../constants/dictionaries';

const jogotInstance = axios.create();

export const postApplicantHandler = ({ payload }) => (
    axios.post(
        urls.APPLICANT.stringify({ vacancy: payload.id || '0' }),
        {
            fields: [
                { tableName: 'personal', name: 'lname', value: payload.lastName },
                { tableName: 'personal', name: 'fname', value: payload.firstName },
                { tableName: 'contact', name: 'contact-phone', value: payload.phone },
                { tableName: 'contact', name: 'email', value: payload.email },
                { tableName: 'contact', name: 'towncode', value: `${payload.town}` },
                { tableName: 'ppleducation', name: 'level_code', value: `${educationValues[payload.educationLevel].level}` },
                { tableName: 'ppleducation', name: 'year_graduation', value: `${educationValues[payload.educationLevel].year}` },
                { tableName: 'GRADUATEINFO', name: 'FLUENTENGLISH_CODE', value: `${payload.languageLevelEn}` },
                { tableName: 'GRADUATEINFO', name: 'PERMISSIONS_TO_WORK', value: (payload.conditions || []).join(', ') },
                { tableName: 'GRADUATEINFO', name: 'WORK_EXPERIENCE_CODE', value: '3' },
                { tableName: 'GRADUATEINFO', name: 'WORK_EXPERIENCE_OTHER', value: `${payload.vuz}` },
                { tableName: 'GRADUATEINFO', name: 'DESIRED_POSITION_STR', value: `${payload.direction}/${payload.directionSecond}` },
                { tableName: 'GRADUATEINFO', name: 'SoglasieOPD', value: '1' },
                { tableName: 'GRADUATEINFO', name: 'DRIVE_SPEC_TECH_CODE', value: '3' },
                { tableName: 'GRADUATEINFO', name: 'DRIVE_SPEC_TECH_OTHER', value: payload.resume }
            ]
        },
    )
);

export const getVacancyHandler = () => axios.get(urls.VACANCY.stringify());

export const getDirectionsHandler = () => jogotInstance.get(urls.DIRECTIONS.stringify(), {
    params: {
        filter: {
            vacancyUnit: '5e621091b90a92001651edc6',
            'published': true,
        },
        pagination: {
            limit: null,
        }
    }
});

export const getDictionaryHandler = ({ payload }) => axios.get(urls.DICTIONARY.stringify({ type: payload.type }));
