import setLocale from 'yup/lib/setLocale';

 export default setLocale({
    mixed: {
        required: 'Это поле обязательно',
    },
    string: {
        email: 'Неверный формат email',
        max: ({ max }) => ({ key: 'validation.max', values: { max } }),
    }
});
