import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ModalContext } from '../../context/modal';

const { Header } = Layout;

const HeaderWrap = styled(Header)`
    width: 100%;
    padding-right: 140px;
    display: flex;
    margin-top: 60px;
    position: absolute;
    right: 0;
    z-index: 1;
    @media (max-width: 1440px) {
        padding-right: 40px;
        margin-top: 40px;
    }
    @media (max-width: 960px) {
        justify-content: center;
        padding: 0;
        flex-direction: column;
        margin-top: 0;
        position: fixed;
        right: inherit;
        z-index: 100;
        width: 200px;
        height: 100%;
        background: #264C9D;
        transition: all 0.5s ease 0s;
        transform: translate3d(-100%, 0px, 0px);
        &.active {
            transform: none;
        }
    }
`;

const MenuWrap = styled(Menu)`
    padding-left: 39%;
    width: 100%;
    background: transparent;
    @media (max-width: 1440px) {
        margin: 0;
        padding-left: 41%;
    }
    @media (max-width: 960px) {
        display: block;
        /*background: red;*/
        top: 0;
        position: absolute;
        margin: 0;
        bottom: 0;
        padding: 60px 0 10px 0;
    }
`;

const Toggle = styled.button`
    display: none;
    @media (max-width: 960px) {
        display: inline-block;
        margin-left: 10px;
        position: fixed;
        top: 10px;
        left: 100%;
        cursor: pointer;
        outline: 0;
        border: 0;
        background: transparent;
    }
`;


export default class HeaderComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            conditionsFooter: true
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (this.props.selected === 'conditions') {
            const el = document.querySelector('.layout-conditions-footer');

            this.setState({ conditionsFooter: el ? window.scrollY > (el.getBoundingClientRect().top + window.pageYOffset - 25) : false });
        } else if (!this.state.conditionsFooter) {
            this.setState({ conditionsFooter: true });
        }
    }

    toggleActive = () => this.setState({ active: !this.state.active });

    render() {
        return (
            <HeaderWrap className={this.state.active && 'active'}>
                <MenuWrap
                    mode="horizontal"
                    selectedKeys={[this.props.selected || 'main']}
                    onSelect={event => this.props.onSelect({
                        ...event,
                        toggleActive: this.toggleActive
                    })}
                    className={cx(`menu-${this.props.selected}`, { 'menu-conditions-footer': this.props.selected === 'conditions' && this.state.conditionsFooter })}
                >
                    <Menu.Item key="main">
                        <Link to='/'>
                            <span>Главная</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="conditions">
                        <Link to='/conditions'>
                            <span>Условия</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="advantages">
                        <Link to='/advantages'>
                            <span>Преимущества</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="requirements">
                        <Link to='/requirements'>
                            <span>Требования</span>
                        </Link>
                    </Menu.Item>
                    {/*
                    <Menu.Item key="directions">
                        <Link to='/directions'>
                            <span>Направления</span>
                        </Link>
                    </Menu.Item>
                    */}
                    <Menu.Item key="about">
                        <Link to='/about'>
                            <span>О компании</span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="modal" className="btn-submit">
                        <ModalContext.Consumer>
                            { ({ showModalApplication }) => (
                                <span onClick={() => showModalApplication()}>Подать заявку</span>
                            )}
                        </ModalContext.Consumer>
                    </Menu.Item>
                </MenuWrap>
                <Toggle className="toggle" onClick={this.toggleActive}>
                    <span className="bar1" />
                    <span className="bar2" />
                    <span className="bar3" />
                </Toggle>
            </HeaderWrap>
        );
    }
}
