import React, { Component } from 'react';
import { Checkbox } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class OpdCheckbox extends Component {
    state = {
        visible: false
    };

    open = () => this.setState({ visible: true });

    close = () => this.setState({ visible: false });

    onChange = e => this.props.input.onChange(e.target.checked);

    openOPD = e => {
        e.preventDefault();
        this.props.openOPD();
    }

    render() {
        const {  input: { value } } = this.props;

        return <div className='opd-checkbox'>
            <Checkbox
                key={value}
                style={{ marginRight: 5 }}
                onChange={this.onChange}
                defaultChecked={!!value}
            >
                <span>
                    {/* eslint-disable-next-line */}
                    Я даю <span className="link-span" onClick={this.openOPD}>согласие</span> на обработку моих персональных данных в соответствии с <a href='https://www.nestle.ru/sites/g/files/pydnoa571/files/2020-02/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B2%20%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%9F%D0%94%D0%BD.pdf' target='_blank' rel="noopener noreferrer">Политикой</a> ООО "Нестле Россия" в отношении обработки персональных данных
                </span>
            </Checkbox>
        </div>;
    }
}

export default withFieldWrapper(OpdCheckbox);
