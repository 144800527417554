import React, { Component } from 'react';
import { Select } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class SelectComponent extends Component {
    render() {
        const { options, input: { name, value }, onChange, valuePath = 'id', namePath = 'name', disabled, placeholder } = this.props;

        return (
            <Select
                value={value || undefined}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                allowClear
            >
                { options.map((option, index) =>
                    <Select.Option key={`option-${name}-${index}`} value={option[valuePath] || option}>
                        { option[namePath] || option}
                    </Select.Option>
                )}
            </Select>
        );
    }
}

export default withFieldWrapper(SelectComponent);
