import React, { Component, Fragment } from 'react';
import { Provider, connect } from 'react-redux';
import { SagaProvider } from 'react-async-client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Alert } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import enGb from 'antd/es/locale/en_GB';
import { IntlProvider }  from 'react-intl';
import { YMInitializer } from 'react-yandex-metrika';

import { sagaMiddleware } from '../store/middlewares';
import { pushRollbarError } from '../utils/rollbarInit';
import { appRun, changeLanguage } from '../actions/appActions';
import ru from '../translations/ru';
import en from '../translations/en';
import Layout from './layout/Layout';

const messages = {
    ru,
    en,
};

class App extends Component {
    state = {
        error: false
    };

    componentDidMount() {
        this.props.appRun();
    }

    componentDidCatch(error, stack) {
        pushRollbarError(error, stack);
        this.setState({ error: true });
    }

    getLocale() {
        const locale = localStorage.getItem('language');

        switch (locale) {
            case 'ru':
                return ruRu;
            case 'en':
                return enGb;
            default:
                return ruRu;
        }

    }

    render() {
        const { store, language } = this.props;

        return <Fragment>
            { process.env.REACT_APP_YAMETRICS &&
                <YMInitializer
                    accounts={[process.env.REACT_APP_YAMETRICS]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true
                    }}
                    version="2"
                />
            }
            { this.state.error ? (
                    <div className="wrap">
                        <Alert type='error' message='Не удалось запустить приложение' />
                    </div>
            ) : (
                <IntlProvider locale={language} messages={messages[language]}>
                    <SagaProvider sagaMiddleware={sagaMiddleware}>
                        <Provider store={store}>
                            <ConfigProvider locale={this.getLocale()}>
                                <BrowserRouter>
                                    <Layout />
                                </BrowserRouter>
                            </ConfigProvider>
                        </Provider>
                    </SagaProvider>
                </IntlProvider>
            )}
        </Fragment>;
    }
}

export default connect(
    ({ language }) => language,
    { appRun, changeLanguage }
)(App);
