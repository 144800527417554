import { CHANGE_LANGUAGE } from '../constants/actionTypes';

const languageDefaultState = {
    language: localStorage.getItem('language') || 'ru',
};

const languageReducer = (state = languageDefaultState, action) => {
    switch(action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        default:
            return state;
    }
};

export default languageReducer;
