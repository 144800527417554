import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Scroll, { Element } from 'react-scroll';
import { throttle } from 'throttle-debounce';

import Main from '../views/Main';
import Conditions from '../views/Conditions';
import Requirements from '../views/Requirements';
import Advantages from '../views/Advantages';
// import Directions from '../views/Directions';
import About from '../views/About';

import Wave from './Wave';
import Header from './Header';

const order = {
    main: 0,
    conditions: 1,
    advantages: 2,
    requirements: 3,
    // directions: 4,
    about: 4,
};

class MobilePages extends Component {
    constructor(props) {
        super(props);

        const pathname = this.props.location.pathname.replace('/', '');
        const key = order[pathname] ? pathname : 'main';

        this.state = {
            selected: key,
        };
    }

    componentDidMount() {
        this.toSlide(this.state.selected, true);

        window.document.addEventListener('scroll', this.handleScroll);
    }

    getTopOffset(id, subId) {
        try {
            return Math.max(0, document.querySelector(id).offsetTop + (subId ? document.querySelector(subId).offsetTop : 0)) - 50;
        } catch {
            return null;
        }
    }

    setRoutePositions = () => {
        this.routesPositions = {
            main: this.getTopOffset('.layout-main'),
            conditions: this.getTopOffset('.layout-conditions'),
            advantages: this.getTopOffset('.layout-advantages'),
            requirements: this.getTopOffset('.layout-requirements'),
            // directions: this.getTopOffset('.layout-directions'),
            about: this.getTopOffset('.layout-about'),
        };
    }

    componentWillUnmount() {
        window.document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = throttle(500, false, (e) => {
        if (!this.routesPositions) {
            this.setRoutePositions();
            return;
        }

        const scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        let target = '';
        if (scrollPosition > this.routesPositions.conditions) {
            target = 'conditions';
        }
        if (scrollPosition > this.routesPositions.advantages) {
            target = 'advantages';
        }
        if (scrollPosition > this.routesPositions.requirements) {
            target = 'requirements';
        }
        // if (scrollPosition > this.routesPositions.directions) {
        //     target = 'directions';
        // }
        if (scrollPosition > this.routesPositions.about) {
            target = 'about';
        }

        if (this.state.selected !== target) {
            this.props.history.push(`/${target}`);
            this.setState({ selected: target });
        }
    })

    onSelect = ({ key, toggleActive }) => {
        key = key.replace('-hidden', '');

        if (key === 'modal') {
            return;
        }

        this.props.history.push('main' === key ? '/' : `/${key}`);
        this.toSlide(key);
        toggleActive();
    };

    toSlide = (key, noAnimate) => {
        const offset = document.querySelector('.layout-' + (key === 'conditions' ? 'conditions-footer' : key)).getBoundingClientRect().top + window.pageYOffset;

        Scroll.animateScroll.scrollTo(offset, {
            duration: noAnimate ? 100 : 500,
            delay: 100,
            smooth: true,
            containerId: 'body',
            offset: 0,
        });
    }

    render() {
        return (
            <div className='layout-mobile slick-slide'>
                <Header selected={this.state.selected} onSelect={this.onSelect} />
                <Wave isMobile={true} />
                <Element name='layout-main' className='layout-main'>
                    <Main slider={this.slider} />
                </Element>
                <Element name='layout-conditions' className='layout-conditions'>
                    <Conditions slider={this.slider} />
                </Element>
                <Element name='layout-advantages' className='layout-advantages'>
                    <Advantages slider={this.slider} />
                </Element>
                <Element name='layout-requirements' className='layout-requirements'>
                    <Requirements slider={this.slider} />
                </Element>
                {/*
                <Element name='layout-directions' className='layout-directions'>
                    <Directions slider={this.slider} />
                </Element>
                */}
                <Element name='layout-about' className='layout-about'>
                    <About slider={this.slider} />
                </Element>
            </div>
        );
    }
}

export default withRouter(MobilePages);
