import React, { Component } from 'react';
import { isMobile } from 'mobile-device-detect';
import { asyncConnect } from 'react-async-client';
import { path } from 'ramda';

import { getDirections, getVacancy } from '../../actions/asyncActions';
import { ModalContextProvider } from '../../context/modal';
import Modals, { ModalErrors } from '../Modals';
import Pages from './Pages';
import MobilePages from './MobilePages';

class LayoutWrap extends Component {
    render() {
        const vacancyId = path(['data', 'id'], this.props.getVacancy);

        return (
            <ModalContextProvider>
                { parseInt(vacancyId, 10) ? (
                    <Modals initialValues={{ id: vacancyId }} vacancy={this.props.getVacancy.data} />
                ) : (
                    <ModalErrors meta={this.props.getVacancy.meta} />
                )}

                { isMobile ? (
                    <MobilePages />
                ) : (
                    <Pages />
                )}
            </ModalContextProvider>
        );
    }
}

const Layout = asyncConnect({
    getVacancy: getVacancy
        .withOptions({ dispatchOnMount: true }),
    getDirections: getDirections
        .withOptions({ dispatchOnMount: true })
})(LayoutWrap);

export default Layout;
