import React, { Component } from 'react';
import { Input } from 'antd';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class InputComponent extends Component {
    static defaultProps = {
        type: 'text'
    };

    render() {
        const { input: { value, name }, className, onChange, placeholder } = this.props;
        const { type, size, maxLength, disabled, password } = this.props;
        const ComponentInput = type === 'password' ? Input.Password : Input;

        return (
            <ComponentInput
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                size={size}
                className={className}
                maxLength={maxLength}
                placeholder={placeholder}
                disabled={disabled}
                password={password}
            />
        );
    }
}

export default withFieldWrapper(InputComponent);
