import { all } from 'redux-saga/effects';
import { getAsyncSagas } from 'react-async-client';

import appRun from './appRun';
import error from './error';
import language from './language';

export default function* rootSaga() {
    yield all([
        ...getAsyncSagas(),
        appRun(),
        error(),
        language(),
    ]);
}
