import Rollbar from 'rollbar';
import { merge, always } from 'ramda';

const defaults = {
    getPayload: always(null),
    domains: ['.*?']
};

export const pushRollbarError = (error, info) => {
    if (process.env.NODE_ENV === 'production') {
        Rollbar.configure({
            payload: {
                info
            }
        });
        Rollbar.error(error.message, error);
    }
}

export default function rollbarInit(options) {
    options = merge(defaults, options);

    window._rollbarConfig = {
        accessToken: options.token,
        captureUncaught: true,
        payload: {
            environment: options.environment,
            client: {
                javascript: {
                    source_map_enabled: true,
                    code_version: options.version,
                    guest_uncaught_frames: true
                }
            }
        },
        transform: payload => {
            const trace = payload.body.trace;
            const host = window.location.host;

            if (trace && trace.frames) {
                for (var i = 0; i < trace.frames.length; i++) {
                    const filename = trace.frames[i].filename;

                    if (filename) {
                        const fileNameWithDynamichost = filename.replace(host, process.env.REACT_APP_ROLLBAR_HOST);
                        trace.frames[i].filename = fileNameWithDynamichost;
                    }
                }
            }
        }
    };

    Rollbar.init(window._rollbarConfig);
}
