
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Form } from 'antd';

import RequiredFieldsContext from '../../context/RequiredFieldsContext';

const withFieldWrapper = WrappedComponent =>
    class FieldWrapper extends Component {
        static propTypes = {
            input: PropTypes.object,
            onChange: PropTypes.func,
            label: PropTypes.string,
            meta: PropTypes.object,
            hide: PropTypes.bool,
            required: PropTypes.bool,
        };

        static defaultProps = {
            hide: false,
            required: true,
        };

        componentDidUpdate(prev) {
            if (prev.hide !== this.props.hide) {
                this.onChange(this.props.hide ? undefined : null);
            }
        }

        onChange = value => {
            const { onChange } = this.props;

            this.props.input.onChange(value);
            onChange && onChange(value);
        }

        render() {
            const { label, meta: { error, submitFailed }, hide, extra, required, noWrap } = this.props;
            const showError = error && submitFailed;

            return hide ? null : <RequiredFieldsContext.Consumer>
                { fields =>
                    noWrap ? (
                        <div className={showError ? 'has-error' : ''}>
                            <WrappedComponent
                                {...this.props}
                                onChange={this.onChange}
                            />
                        </div>
                    ) : (
                        <Form.Item
                            label={label}
                            validateStatus={showError ? 'error' : ''}
                            help={showError ? error : null}
                            extra={extra}
                            className={cx({ error })}
                            required={required}>
                            <WrappedComponent
                                {...this.props}
                                onChange={this.onChange}
                            />
                        </Form.Item>
                    )
                }
            </RequiredFieldsContext.Consumer>;
        }
    }

export default withFieldWrapper;
