import React, { Component } from 'react';

import { init } from '../../utils/shader/wave';

export default class Wave extends Component {
    state = {
        ready: false,
    }

    componentDidMount() {

        setTimeout(() => {
            this.setState({ ready: true });
        }, 1000);

        if (this.props.isMobile) {
            this.setState({
                left: `${- parseInt(document.body.clientHeight / 2, 10) - 100}px`,
                width: `${parseInt(document.body.clientHeight + 400, 10)}px`,
                height: `${parseInt(document.body.clientHeight, 10)}px`,
            });
        }

        setTimeout(() => {
            init(this.props.isMobile);
        }, 100);
    }

    render() {
        return (
            <div
                className={`particles ${!this.state.ready && 'noanimation'}`}
                style={this.state.width ? {
                    left: this.state.left,
                    width: this.state.width,
                    height: this.state.height,
                } : this.props.waveStyle || null}
            />
        );
    }
}
