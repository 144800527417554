import React, { Component } from 'react';
import styled from 'styled-components'
import { Button } from 'antd';
import imgPhoto from '../../assets/img/b-photo.png';
import imgLetter from '../../assets/img/b-letter.png';
import imgMin from '../../assets/img/b-min.png';

const Wrap = styled.div`
    padding: 100px 140px 50px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    max-width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        background-image: url(${imgPhoto});
        background-size: 340px auto;
        background-repeat: no-repeat;
        background-position: 13.8% center;
        top: 50%;
        left: 20%;
        width: 340px;
        height: 470px;
        margin-left: -139px;
        margin-top: -300px;
        z-index: 3;
    }
    @media (max-width: 1440px) {
        padding: 100px 40px 50px 0;
        &:after {
            background-size: 270px auto;
            width: 270px;
            height: 355px;
            margin-left: -106px;
            margin-top: -240px;
        }
    }
    @media (min-width: 961px) {
        height: 100vh;
    }
    @media (max-width: 960px) {
        padding: 0;
        &:after {
            background-size: 230px auto;
            width: 230px;
            height: 300px;
            margin-left: -115px;
            margin-top: 0;
            top: 40px;
            left: 50%;
            background-image: url(${imgMin});
        }
    }
    @media (max-width: 767px) {
        &:after {
            background-size: 150px auto;
            width: 150px;
            height: 200px;
            margin-left: -75px;
            top: 20px;
        }
    }
`;

const Bg = styled.div`
        position: absolute;
        background-image: url(${imgLetter});
        background-size: 340px auto;
        background-repeat: no-repeat;
        background-position: 13.8% center;
        top: 50%;
        left: 20%;
        width: 340px;
        height: 470px;
        margin-left: -139px;
        margin-top: -300px;
        z-index: -1;
        @media (max-width: 1440px) {
            background-size: 270px auto;
            width: 270px;
            height: 355px;
            margin-left: -106px;
            margin-top: -240px;
        }
        @media (max-width: 960px) {
            display: none;
        }
`;

const ArrowButton = styled(Button)`
    width: 136px;
    height: 126px;
    margin-right: -50px;
    border: 0!important;
    &:before {
        content: '';
        background: transparent;
        background-repeat: no-repeat;
        opacity: 1;
        display: inline-block;
        width: 137px;
        height: 126px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzcuMDcgMTI2LjM4Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPtCg0LXRgdGD0YDRgSA2PC90aXRsZT48ZyBpZD0i0KHQu9C+0LlfMiIgZGF0YS1uYW1lPSLQodC70L7QuSAyIj48ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik02MS4zMSw2MC43NUgwdjEzSDcyLjIyWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTk0LjYzLDEwMC40Niw3Ny41NCwxMTcuNTVjMy4zNCwzLjExLDYuNDUsNiw5LjQ1LDguODNxOC04LDE2LTE2WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTk0LDEwMi4wNSwxMzcuMDcsNTlsLTU5LTU5TDY4LjI1LDkuMTFsNDMuNDQsNDMuNDRINTIuNDZsMTAuOTEsMTNoNDguODRMODUuNjUsOTIuMTFaIi8+PC9nPjwvZz48L3N2Zz4=');
    }
    @media (max-width: 1440px) {
        margin-right: -25px;
        margin-left: 20px;
        width: 100px;
        height: 92px;
        &:before {
            background-size: 100px auto;
            width: 100px;
            height: 92px;
        }
    }
    @media (max-width: 960px) {
        display: none;
    }
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (max-width: 960px) {
        align-items: center;
    }
`;

const Content = styled.div`
    display: flex;
    padding-left: 39%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex: 12 0 auto;
    p {
        max-width: 630px;
        font-size: 28px;
        line-height: 30px;
        margin: 0;
        color: #fff;
        @media (max-width: 1440px) {
            max-width: 540px;
            font-size: 22px;
        }
    }
    .ant-btn {
        margin-top: 130px;
    }
    @media (max-width: 1440px) {
        padding-left: 41%;
        p {
            max-width: 540px;
            font-size: 22px;
        }
    }
    @media (max-width: 960px) {
        min-height: 100vh;
        padding: 300px 40px 40px;
        align-items: flex-start;
        p {
            width: 100%;
            font-size: 22px;
            color: #264C9D;
            margin-top: 20px;
        }
    }
    @media (max-width: 767px) {
        padding: 220px 30px 30px;
        align-items: flex-start;
        p {
            font-size: 16px;
            margin-top: 10px;
            line-height: normal;
        }
    }
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 20%;
    flex: 1 0 auto;
    margin-bottom: -25px;
    @media (max-width: 960px) {
        padding: 40px;
        background-color: #264C9D;
        min-height: 100vh;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 0px;
    }
`;

const Item = styled.div`
    color: #fff;
    font-size: 22px;
    padding-right: 20px;
    b {
        display: block;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0;
    }
    @media (max-width: 1440px) {
        font-size: 18px;
    }
    @media (max-width: 960px) {
        max-width: 540px;
        width: 100%;
        padding: 0;
        font-size: 16px;
    }
`;

export default class Conditions extends Component {
    render() {
        return (
            <Wrap>
                <Bg />
                <ContentWrap className="container">
                    <Content>
                        <div>
                            <p>Маркетинговые стратегии, финансовые потоки, логистические цепочки и внутренние процессы — что помогает всем составляющим бизнеса Нестле работать слаженно?</p>
                            <p>Это особый ритм, который можно ощутить на оплачиваемой стажировке Nestlé Beat. </p>
                            <p>Выберите одно из 8 направлений, поработайте вместе с экспертами и узнайте, как бьется сердце мирового FMCG-лидера.</p>
                        </div>
                        <ArrowButton type="link" size="large" onClick={this.props.nextSlide} />
                    </Content>
                    <Footer className='layout-conditions-footer'>
                        <Item>
                            <b>Длительность</b>
                            <p>6 месяцев</p>
                        </Item>
                        <Item>
                            <b>Условия</b>
                            <p>Фиксированный&nbsp;оклад <br />Компенсация питания <br />Полная занятость</p>
                        </Item>
                        <Item>
                            <b>Место</b>
                            <p>
                                Центральный офис «Нестле Россия» в Москве, м. «Павелецкая» <br />
                                Региональные офисы в других городах
                            </p>
                        </Item>
                        <Item>
                            <b>Дата начала</b>
                            <p>Набор на стажировку открыт в течение всего года</p>
                        </Item>
                    </Footer>
                </ContentWrap>
            </Wrap>
        );
    }
}

