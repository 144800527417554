import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { filter, propEq } from 'ramda';

import withFieldWrapper from '../../hocs/withFieldWrapper';

class SearchInput extends Component {
    static propTypes = {
        field: PropTypes.object,
        options: PropTypes.array,
        placeholder: PropTypes.string
    };

    static defaultProps = {
        options: []
    };

    state = {
        enableSearch: false
    };

    onChange = value => {
        this.props.input.onChange(value);

        this.setState({
            enableSearch: false
        });
    }

    onSearch = value => {
        const enableSearch = value.length >= 2;

        this.setState({ enableSearch });
    }

    getFilterOption(input, option) {
        const optionText = option.props.children.toLowerCase();
        const searchText = input.toLowerCase().trim();

        return optionText.indexOf(searchText) >= 0;
    }

    getNotFoundContent() {
        return this.state.enableSearch ? 'Ничего не найдено' : 'Введите название для поиска';
    }

    renderOptions(options) {
        return options ? options.map(item =>
            <Select.Option key={item.id} value={item.id}>
                { item.name }
            </Select.Option>
        ) : null;
    }

    renderSelectedOption(options, value) {
        const filteredOptions = value && filter(propEq('id', Number(value)), options);

        return this.renderOptions(filteredOptions);
    }

    render() {
        const { enableSearch } = this.state;
        const { options, placeholder, input: { value, name } } = this.props;

        return (
            <Select
                value={value || undefined}
                name={name}
                allowClear={true}
                onChange={this.onChange}
                placeholder={placeholder}
                showSearch={true}
                optionFilterProp='children'
                filterOption={this.getFilterOption}
                onSearch={this.onSearch}
                notFoundContent={this.getNotFoundContent()}
                showArrow={false}>
                { enableSearch ? this.renderOptions(options) : this.renderSelectedOption(options, value) }
            </Select>
        );
    }
}

export default withFieldWrapper(SearchInput);
