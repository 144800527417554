import React, { Component } from 'react';
import styled from 'styled-components'
import { Button } from 'antd';
import imgMin from '../../assets/img/a.png';
import imgPhoto from '../../assets/img/a-photo.png';
import imgLetter from "../../assets/img/a-letter.png";

const Wrap = styled.div`
    padding: 100px 140px 50px 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    max-width: 100%;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        background-image: url(${imgPhoto});
        background-size: 295px auto;
        background-repeat: no-repeat;
        background-position: 13.8% center;
        top: 50%;
        left: 20%;
        width: 295px;
        height: 359px;
        margin-left: -147px;
        margin-top: -180px;
        z-index: 2;
    }
    @media (max-width: 1440px) {
        padding: 100px 40px 50px 0;
        &:after {
            background-size: 210px auto;
            width: 210px;
            height: 258px;
            margin-left: -95px;
            margin-top: -150px;
        }
    }
    @media (min-width: 961px) {
        height: 100vh;
    }
    @media (max-width: 960px) {
        padding: 0;
         &:after {
            background-image: url(${imgMin});
            top: 40px;
            left: 50%;
            background-size: 215px auto;
            width: 215px;
            height: 265px;
            margin-left: -115px;
            margin-top: 0;
        }
    }
    @media (max-width: 767px) {
        &:after {
            background-size: 150px auto;
            width: 150px;
            height: 200px;
            margin-left: -75px;
            top: 20px;
        }
    }
`;

const Bg = styled.div`
        position: absolute;
        background-image: url(${imgLetter});
         background-size: 295px auto;
        background-repeat: no-repeat;
        background-position: 13.8% center;
        top: 50%;
        left: 20%;
        width: 295px;
        height: 359px;
        margin-left: -147px;
        margin-top: -180px;
        z-index: -1;
        @media (max-width: 1440px) {
            background-size: 210px auto;
            width: 210px;
            height: 258px;
            margin-left: -95px;
            margin-top: -150px;
        }
        @media (max-width: 960px) {
            display: none;
        }
`;

const ArrowButton = styled(Button)`
    width: 136px;
    height: 126px;
    margin-right: -50px;
    border: 0!important;
    &:before {
        content: '';
        background: transparent;
        background-repeat: no-repeat;
        opacity: 1;
        display: inline-block;
        width: 137px;
        height: 126px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzcuMDcgMTI2LjM4Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPtCg0LXRgdGD0YDRgSA2PC90aXRsZT48ZyBpZD0i0KHQu9C+0LlfMiIgZGF0YS1uYW1lPSLQodC70L7QuSAyIj48ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik02MS4zMSw2MC43NUgwdjEzSDcyLjIyWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTk0LjYzLDEwMC40Niw3Ny41NCwxMTcuNTVjMy4zNCwzLjExLDYuNDUsNiw5LjQ1LDguODNxOC04LDE2LTE2WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTk0LDEwMi4wNSwxMzcuMDcsNTlsLTU5LTU5TDY4LjI1LDkuMTFsNDMuNDQsNDMuNDRINTIuNDZsMTAuOTEsMTNoNDguODRMODUuNjUsOTIuMTFaIi8+PC9nPjwvZz48L3N2Zz4=');
    }
    @media (max-width: 1440px) {
        margin-right: -25px;
        margin-left: 20px;
        width: 100px;
        height: 92px;
        &:before {
            background-size: 100px auto;
            width: 100px;
            height: 92px;
        }
    }
    @media (max-width: 960px) {
        display: none;
    }
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const TextWrap = styled.div`
    max-width: 630px;
    @media (max-width: 1440px) {
        max-width: 540px;
    }
`;

const Content = styled.div`
    display: flex;
    padding-left: 39%;
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex: 12 0 auto;
    color: #fff;
    h2 {
        color: #fff;
        font-size: 30px;
        line-height: 32px;
        font-weight: bold;
        margin: 0 0 40px;
    }
    ul {
        font-size: 25px;
        line-height: 30px;
        padding-left: 0;
        li {
            margin-bottom: 40px;
        }
    }
    @media (max-width: 1440px) {
        h2 {
            font-size: 24px;
            line-height: 26px;
            margin: 0 0 30px;
        }
        ul {
            font-size: 20px;
            line-height: 24px;
            padding-left: 0;
            li {
                margin-bottom: 25px;
            }
        }
    }
    @media (max-width: 960px) {
        color: #264C9D;
        padding: 250px 20px 20px 40px;
        align-items: flex-start;
        justify-content: center;
        h2 {
            color: #264C9D;
        }
    }
    @media (max-width: 767px) {
        h2 {
            font-size: 20px;
            line-height: 24px;
            margin: 0 0 20px;
        }
        ul {
            font-size: 14px;
            line-height: 18px;
            li {
                margin-bottom: 15px;
            }
        }
    }
`;

export default class Requirements extends Component {
    render() {
        return (
            <Wrap>
                <Bg />
                <ContentWrap className="container">
                    <Content>
                        <TextWrap>
                            <h2>Чтобы стать стажером Нестле, нужно:</h2>
                            <ul>
                                <li>Быть студентом последнего курса бакалавриата, магистратуры или выпускником</li>
                                <li>Быть готовым работать 40 часов в неделю</li>
                                <li>Владеть английским языком на уровне не ниже intermediate</li>
                                <li>Стремиться к результату, быть готовым к реальной работе и серьезным проектам</li>
                            </ul>
                        </TextWrap>
                        <ArrowButton type="link" size="large" onClick={this.props.nextSlide} />
                    </Content>
                </ContentWrap>
            </Wrap>
        );
    }
}

