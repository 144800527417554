import { takeEvery } from 'redux-saga/effects';

import { REQUEST_ERROR } from '../constants/regExp';

export default function* error() {
    yield takeEvery((action) => REQUEST_ERROR.test(action.type), function (action) {
        const error = action.payload;

        console.error(error);
    });
}
